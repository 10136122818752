import '@/global/dayjs'
import '@/global/number'
import useAppPage from '@/hooks/use-app-page'
import useUpgrade from '@/hooks/useUpgrade'
import { Token } from '@/models/auth/token'
import '@/styles/globals.scss'
import ErrorBoundary from '@/ui/components/shared/error-boundary'
import { RootConfig, RootLayout } from '@/ui/components/shared/layout'
import Loading from '@/ui/widgets/loading'
import { canUseDom, resource } from '@/utils/helper'
import packSensors from '@/utils/sensor'
import { retrieveToken } from '@/utils/token'
import { getDeviceType } from '@/utils/userAgent'
import { Modal, Spin } from 'antd'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import NProgress from 'nprogress'
import React, { ReactElement, useCallback, useEffect } from 'react'

Spin.setDefaultIndicator(<Loading />)

interface PageProps {
  Component: typeof React.Component & { getLayout?: (props: any) => ReactElement }
  pageProps: any
}

const Heads = () => (
  // eslint-disable-next-line @next/next/no-script-component-in-head
  <Head>
    <title>蔡司工业质量商城</title>
    <meta content="maximum-scale=1, user-scalable=no" name="viewport" />
    <link rel="icon" href={resource('/favicon.png')} />
  </Head>
)

const isInMiniProgram = () => {
  let result = false
  if (canUseDom()) {
    try {
      result = window.navigator.userAgent.includes('miniProgram')
    } catch (error) {
      result = false
    }
  }
  return result
}

console.log('app 1-2：', process.env.NEXT_PUBLIC_APP_ENV, isInMiniProgram())

export default function Page({ Component, pageProps }: PageProps) {
  const router = useRouter()
  const { events, pathname: currentPathname } = router

  const appPage = useAppPage(
    <ErrorBoundary>
      <Component {...pageProps} />
    </ErrorBoundary>
  )

  const renderWithLayout = (props: any) => {
    return <RootConfig>{Component.getLayout ? Component.getLayout(props) : <RootLayout>{props}</RootLayout>}</RootConfig>
  }

  const onNProgressStart = useCallback(
    (nextPath: string) => {
      const nextPathname = nextPath.split('?')[0]
      if (!nextPathname.endsWith(currentPathname)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        NProgress.start()
      }
    },
    [currentPathname]
  )
  const onNProgressDone = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    NProgress.done()
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    NProgress.configure({ showSpinner: false })
    events.on('routeChangeStart', onNProgressStart)
    events.on('routeChangeComplete', onNProgressDone)
    events.on('routeChangeError', onNProgressDone)
    return () => {
      events.off('routeChangeStart', onNProgressStart)
      events.off('routeChangeComplete', onNProgressDone)
      events.off('routeChangeError', onNProgressDone)
    }
  }, [events, onNProgressDone, onNProgressStart])

  useEffect(() => {
    return () => {
      Modal.destroyAll()
    }
  }, [currentPathname])

  const tokenPersisted = retrieveToken<Token>()
  useEffect(() => {
    if (packSensors.sensors) {
      const accessToken = tokenPersisted?.accessToken
      packSensors.sensors.registerPage({
        platform_type: 'web',
        pageArea: 'web',
        pageCountry: 'China',
        pagePool: 'IQS',
        project: 'IQS E-Commerce',
        pageIdentifier: 'IQS E-Commerce',
        device: getDeviceType(),
        is_login: !!accessToken,
      })
      packSensors.login(accessToken)
      console.log('init sensors', tokenPersisted)
      packSensors.sensors.quick('autoTrack')
    }
  }, [])

  const { pass, LoadingDom } = useUpgrade()
  if (!pass) {
    return <LoadingDom />
  }

  return (
    <>
      <Heads />
      {isInMiniProgram() ? null : <Script src={`${process.env.NEXT_PUBLIC_BASE_PATH || ''}/bai.js`} />}
      {renderWithLayout(appPage)}
    </>
  )
}
