import { FEED_BACK_URL, TELE_NUMBER } from '@/constants/global'
import Button from '@/ui/widgets/button'
import Image from '@/ui/widgets/image'
import cls from 'classnames'
import Link from 'next/link'
import FeedBackIcon from 'public/message-text.svg'
import SwapRightOutline from 'public/swap-right-outline.svg'
import React, { FC, useEffect, useState } from 'react'

import styles from './index.module.scss'
import CustomerServiceIcon from '/public/head-set.svg'

interface CustomerServiceContentProps {
  hidePopover?: () => void
  mode?: 'custom'
}

export const CustomerServiceContent: FC<CustomerServiceContentProps> = ({ hidePopover, mode }) => {
  return (
    <div className={cls(styles.popover, { [styles.popoverClearPosition]: mode === 'custom' })}>
      <div className="flex  mb-3 justify-between">
        <p className="font-bold text-gray-600">联系电话</p>
        <Link className="flex items-center justify-center gap-2 hover:cursor-pointer" href={FEED_BACK_URL} target="_blank">
          <FeedBackIcon className="fill-blue-500 w-3 h-3" />
          <p className="text-blue-500 text-xs font-light">意见反馈</p>
        </Link>
      </div>
      <div className="w-full bg-gray-100 rounded-sm p-4 mb-3">
        <p className="font-bold text-xl mb-0.5 text-gray-600">{TELE_NUMBER}</p>
        <p className="text-xs text-gray-500">工作时间：工作日 9:00-18:00</p>
      </div>
      <div className="w-full flex flex-col items-center mb-3">
        <Image src="/work-qrcode.png" alt="" width="72" height="72" />
        <span className="mt-1 text-base text-gray-500">商城客服号</span>
      </div>
      <p className="text-xs text-gray-500">您可以在工作时间拨打客服热线，与我们的技术专家取得联络。</p>
      <div className="mt-3 flex justify-end">
        <Link href="/contact-sales" target="_blank">
          <Button type="primary" className="flex gap-1 w-24 h-10 bg-blue-500 rounded-sm items-center hover:bg-blue-600" onClick={hidePopover}>
            <span className="text-xs text-white font-bold">预约回电</span>
            <SwapRightOutline className="stroke-white w-4 h-4" />
          </Button>
        </Link>
      </div>
    </div>
  )
}

const CustomerService: FC = () => {
  const [showPopover, setShowPopover] = useState(false)
  const togglePopover = (event: React.MouseEvent): void => {
    event.stopPropagation()
    setShowPopover((currentValue) => !currentValue)
  }
  const hidePopover = () => setShowPopover(false)

  useEffect(() => {
    window.addEventListener('click', hidePopover)
    return (): void => {
      window.removeEventListener('click', hidePopover)
    }
  }, [])

  return (
    <div
      className="rounded-full bg-white shadow flex items-center fixed right-8 bottom-60 lg:right-16 lg:bottom-[6.375rem] relevant z-modal"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="cursor-pointer z-modal p-5" onClick={togglePopover}>
        <CustomerServiceIcon data-testid="customer-service-icon" className="fill-gray-600 w-6 h-6" />
      </div>

      {showPopover && <CustomerServiceContent hidePopover={hidePopover} />}
    </div>
  )
}

export default CustomerService
